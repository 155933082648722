/* body{
    min-height: 100vh;
    display: grid;
    place-items: center;
} */

.slide {
  width: 100%;
  display: grid;
  place-items: center;
  justify-content: center;
  padding: 25px;
  perspective: 100px;
}
/* .slide {
  height: 150px;
  width: 200px;
  display: grid;
  place-items: center;
  padding: 15px;
  perspective: 100px;
} */

.slide img {
  height: auto;
  width: 110%;
  transition: transform 1s;
}
.image-slider {
  display: grid;
  place-items: center;

  position: relative;

  overflow: hidden;
  height: 50vb;
  width: 100%;
}
.image-slider-track {
  display: flex;
  width: calc(200px * 15);
  /* animation: play 40s linear infinite; */
  animation: scrollLoop 20s linear infinite;
}

@keyframes scrollLoop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
/* 
@keyframes play {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 6));
  }
} */

.image-slider::before,
.image-slider::after {
  background: linear-gradient(
    to right,
    /* rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100% */
      rgba(9, 9, 23, 1) 0%,
    rgba(255, 255, 255, 0) 50%
  );
  opacity: 0.5;
  content: "";
  height: 100%;
  width: 15%;
  z-index: 2;
  position: absolute;
}

.image-slider::before {
  left: 0;
  top: 0;
}

.image-slider::after {
  right: 0;
  top: 0;
  background: linear-gradient(
    to left,
    /* rgba(255, 255, 255, 1) 0%, */ rgba(9, 9, 23, 1) 0%,
    rgba(255, 255, 255, 0) 50%
  );
}

img:hover {
  transform: translateZ(20px);
}

@media (max-width: 500px) {
  .slide {
    width: 100%;
    display: grid;
    place-items: center;
    justify-content: center;
    padding: 25px;
    perspective: 100px;
  }
}
@media screen and (max-width: 768px) {
  .image-slider-track {
    width: calc(200px * 15);
  }
}
@media screen and (max-width: 300px) {
  .image-slider-track {
    width: calc(200px * 12);
  }
  .image-slider {
    display: grid;
    place-items: center;

    position: relative;

    overflow: hidden;
    height: 40vb;
    width: 100%;
  }
}
